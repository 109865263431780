<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    v-if="emailInvoiceModalVisible"
    class="fixed inset-0 overflow-y-auto"
    :class="safeToShow ? 'z-20' : '-z-20'"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity duration-200 ease-in-out" aria-hidden="true"
        :class="safeToShow ? 'opacity-100' : 'opacity-0'"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline"
        :class="safeToShow ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col">

            <label for="email" class="text-il-dark font-bold uppercase text-xs">Email</label>
            <input
              type="text"
              id="email"
              name="email"
              class="w-full rounded border border-gray-300 focus:border-il-dark text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              v-model="email"
              :disabled="emailSendRequestLoading"
            >

            <label for="contact_name" class="text-il-dark font-bold uppercase text-xs mt-4">Greeting</label>
            <input
              type="text"
              id="contact_name"
              name="contact_name"
              class="w-full rounded border border-gray-300 focus:border-il-dark text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              v-model="contactName"
              :disabled="emailSendRequestLoading"
            >

            <div class="text-il-dark font-bold uppercase text-xs mt-4">
              message
            </div>
            <textarea
              ref="modalEmailMessageRef"
              class="outline-none border border-gray-300 focus:border-il-dark text-gray-700 px-2 py-1 transition-all duration-200 ease-in-out"
              rows='1'
              data-min-rows='1'
              data-height-step='28'
              v-model="emailMessage"
              :disabled="emailSendRequestLoading"
              @input="autoResizeMX($event.target, 28)"
            >
            </textarea>

            <label for="link_message" class="text-il-dark font-bold uppercase text-xs mt-4">invoice link message</label>
            <input
              type="text"
              id="link_message"
              name="link_message"
              class="w-full rounded border border-gray-300 focus:border-il-dark text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              v-model="invoiceLinkMessage"
              :disabled="emailSendRequestLoading"
            >

          </div>

          <div class="mt-8 flex flex-col py-2">
            <div class="text-center py-1 p-2 bg-il-dark text-white text-lg rounded-t-lg">
              Email Preview
            </div>
            <EmailInvoiceModalPreview
              :email="email"
              :contactName="contactName"
              :emailMessage="emailMessage"
              :invoiceLinkMessage="invoiceLinkMessage"
              :invoice="invoice"
            />
          </div>
        </div>

        <div
          class="bg-gray-50 px-4 py-3 flex flex-wrap justify-between"
        >
          <div v-if="!emailSendRequestLoading" class="flex w-full md:block md:w-auto">
            <button
              @click="markAsSent"
              type="button"
              class="w-full md:w-auto mt-1 py-1 px-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark text-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out"
            >
              Mark as Already Sent
            </button>
          </div>

          <div v-if="!emailSendRequestLoading" class="flex flex-wrap w-full md:block md:w-auto">
            <button
              @click="hidesEmailInvoiceModal"
              type="button"
              class="w-full md:w-auto mt-1 py-1 px-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark text-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out"
            >
              Cancel
            </button>
            <button
              @click="sendInvoice"
              type="button"
              class="w-full md:w-auto mt-1 md:ml-2 py-1 px-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark text-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out"
            >
              Send Invoice <span class="ml-1"><i class="fas fa-paper-plane"></i></span>
            </button>
          </div>

          <div
            v-if="emailSendRequestLoading"
            class="ml-2 py-1 px-2 uppercase rounded-lg text-sm border border-il-dark bg-il-dark text-white"
          >
            Processing <span class="ml-1"><i class="fas fa-circle-notch fa-spin text-white"></i></span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import clientInvoiceRequests from '../../helpers/clientInvoiceRequests';
  import EmailInvoiceModalPreview from './EmailInvoiceModalPreview';

  export default {
    props: ['emailInvoiceModalVisible','client','invoice'],
    emits: ['update:emailInvoiceModalVisible'],
    mounted() {

    },
    data() {
      return {
        emailSendRequestLoading: false,
        safeToShow: false,
        email: null,
        contactName: null,
        emailMessage: null,
        invoiceLinkMessage: null
      }
    },
    components: {
      EmailInvoiceModalPreview
    },
    methods: {
      ...mapMutations([
        'setClientInvoiceSentStatusX',
        'updateClientInvoiceX'
      ]),
      hidesEmailInvoiceModal() {
        this.safeToShow = false;
        setTimeout(() => {
          this.updateValue('emailInvoiceModalVisible')
        }, 300);
      },
      updateValue(prop,value) {
        this.$emit('update:'+prop,value);
      },
      markAsSent() {
        if(!this.emailSendRequestLoading) {
          this.emailSendRequestLoading = true;
          clientInvoiceRequests.markSent(
            this.invoice.id,
            this.email,
            this.contactName,
            this.emailMessage,
            this.invoiceLinkMessage
          )
          .then((response) => {
            this.setClientInvoiceSentStatusX(response.data.invoice);
            this.popSuccessMessageMX(response.data.message);
            this.hidesEmailInvoiceModal();
            this.emailSendRequestLoading = false;
          }).catch(error => {
            this.popErrorsMX(error.response.data.errors);
            this.emailSendRequestLoading = false;
          });
        }
      },
      sendInvoice() {
        if(!this.emailSendRequestLoading) {
          if(this.isInGracePeriodMX(this.getUserX.trialEndsAt) <= 0 || this.getIsOnTrialX || this.getIsSubscribedX) {
            this.emailSendRequestLoading = true;
            clientInvoiceRequests.sendInvoice(
              this.invoice.id,
              this.email,
              this.contactName,
              this.emailMessage,
              this.invoiceLinkMessage
            )
            .then((response) => {
              this.updateClientInvoiceX(response.data.invoice);
              // this.setClientInvoiceSentStatusX(response.data.invoice);
              this.popSuccessMessageMX(response.data.message);
              this.hidesEmailInvoiceModal();
              this.emailSendRequestLoading = false;
            }).catch(error => {
              console.log(error);
              this.popErrorsMX(error.response.data.errors);
              this.emailSendRequestLoading = false;
            });
          } else {
            this.popErrorMX("Subscribe to Premium Plan to send Emails");
          }
        }
      }
    },
    computed: {
      ...mapGetters([
        'getUserX',
        'getIsOnTrialX',
        'getIsSubscribedX'
      ])
    },
    watch: {
      emailInvoiceModalVisible: function (val) {
        if(val == true) {
          setTimeout(() => {
            this.safeToShow = true;
          }, 100);
        }
      },
      invoice: function (val) {
        if(val) {
          this.email = this.client.email;
          this.contactName = this.client.contact_name;
          this.emailMessage = this.client.email_message;
          this.invoiceLinkMessage = this.client.invoice_link_message;

          if(this.$refs.modalEmailMessageRef) {
            this.$nextTick(function() {
              this.autoResizeMX(this.$refs.modalEmailMessageRef,28);
            });
          }
        }
      },
      // safeToShow: function(val) {
      //   if(val) {
      //     this.$nextTick(function() {
      //       this.autoResizeMX(this.$refs.modalEmailMessageRef,28);
      //     });
      //   }
      // }
    }
  }
</script>