<template>
  <div class="p-2 shadow-lg rounded-b-lg flex flex-col">
    <div v-if="contactName" class="font-bold">
      {{ contactName }},
    </div>

    <div
      v-if="emailMessage"
    >
      <div v-html="nl2brMX(emailMessage)"></div>
    </div>

    <a
      v-if="invoiceLinkMessage"
      class="text-il-purple mt-4 text-center block text-xs font-bold uppercase"
      target="_blank"
      :href="$store.state.siteUrl + 'invoice/client-preview/' + invoice.company_uuid + '/' + invoice.client_uuid + '/' + invoice.uuid"
    >
      {{ invoiceLinkMessage }}
    </a>
  </div>
</template>

<script>
  export default {
    props: ['invoice','email','contactName','emailMessage','invoiceLinkMessage'],
  }
</script>