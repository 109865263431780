<template>
  <div class="w-full m-8 bg-white rounded-lg p-4 relative">
    <EmailInvoiceModal
      v-model:emailInvoiceModalVisible="emailInvoiceModalVisible"
      :client="selectedClient"
      :invoice="selectedInvoice"
    />

    <div
      @click="navigateToMX('clients')"
      class="absolute flex items-center top-4 text-il-dark-faded hover:text-il-dark duration-200 transition-all ease-in-out cursor-pointer"
    >
      <i class="fas fa-chevron-left fa-2x"></i>
      <span class="ml-4">Back to Clients</span>
    </div>

    <div
      class="flex justify-center text-2xl text-il-dark font-bold"
    >
      {{ client.name }}
    </div>

    <div
      class="text-center text-gray-400 uppercase text-sm mt-2"
    >
      Invoices History
    </div>

    <div class="flex flex-col mt-4">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-il-dark">
                <tr>
                  <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                    Serial #
                  </th>
                  <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                    Date Created
                  </th>
                  <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                    Sent
                  </th>
                  <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                    Seen
                  </th>
                  <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                    Paid
                  </th>
                  <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>


              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="invoice in orderedInvoices"
                  :key="invoice.id"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">{{ invoice.invoice_serial }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span class="text-xs uppercase text-il-teal text-right font-bold w-9 mr-1 inline-block">date:</span> {{ formatDate(invoice.created_at) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex">
                      <div class="flex flex-col">
                        <div v-if="!invoice.sent_at" class="flex">
                          <div class="font-bold text-red-600">
                            No
                          </div>
                          <div
                            @click="showEmailInvoiceModal(client,invoice)"
                            v-tippy="{ content: 'Send or Mark as Sent' }"
                            class="pr-2px ml-2 cursor-pointer border border-gray-500 text-gray-500 text-xs hover:bg-il-teal hover:text-white rounded-full w-5 h-5 flex items-center justify-center transition-all duration-200 ease-in-out"
                          ><i class="fas fa-paper-plane"></i></div>
                        </div>
                        <div v-if="invoice.sent_at">
                          <span class="text-xs uppercase text-il-teal text-right font-bold w-9 mr-1 inline-block">To:</span> {{ invoice.sent_to_email }}
                        </div>
                        <div v-if="invoice.sent_at">
                          <span class="text-xs uppercase text-il-teal text-right font-bold w-9 mr-1 inline-block">date:</span> {{ formatDate(invoice.sent_at) }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex">
                      <div class="flex flex-col">
                        <div v-if="!invoice.seen_at" class="flex">
                          <div class="font-bold text-red-600">
                            No
                          </div>
                          <div
                            v-if="!markSeenLoading"
                            @click="markAsSeen(invoice.id)"
                            v-tippy="{ content: 'Mark as Seen' }"
                            class="ml-2 cursor-pointer border border-gray-500 text-gray-500 text-xs hover:bg-il-teal hover:text-white rounded-full w-5 h-5 flex items-center justify-center transition-all duration-200 ease-in-out"
                          ><i class="fas fa-binoculars"></i></div>
                          <div class="ml-2" v-else>
                            <i class="fas fa-circle-notch fa-spin text-il-teal"></i>
                          </div>
                        </div>
                        <div v-if="invoice.seen_at">
                          <span class="text-xs uppercase text-il-teal text-right font-bold w-9 mr-1 inline-block">date:</span> {{ formatDate(invoice.seen_at) }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex">
                      <div class="flex flex-col">
                        <div v-if="!invoice.paid_at" class="flex">
                          <div class="font-bold text-red-600">
                            No
                          </div>
                          <div
                            v-if="!markPaidLoading"
                            @click="markAsPaid(invoice.id)"
                            v-tippy="{ content: 'Mark as Paid' }"
                            class="ml-2 cursor-pointer border border-gray-500 text-gray-500 text-xs hover:bg-il-teal hover:text-white rounded-full w-5 h-5 flex items-center justify-center transition-all duration-200 ease-in-out"
                          ><i class="fas fa-dollar-sign"></i></div>
                          <div class="ml-2" v-else>
                            <i class="fas fa-circle-notch fa-spin text-il-teal"></i>
                          </div>
                        </div>
                        <div v-if="invoice.paid_at">
                          <span class="text-xs uppercase text-il-teal text-right font-bold w-9 mr-1 inline-block">date:</span> {{ formatDate(invoice.paid_at) }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="flex px-6 py-4 whitespace-nowrap text-xl text-gray-500">
                    <div>
                      <span
                        v-tippy="{ content: 'Invoice Email Sent' }"
                        v-if="invoice.sent_at"
                        class="text-il-teal"
                      >
                        <i class="fas fa-paper-plane"></i>
                      </span>
                      <span
                        v-tippy="{ content: 'Invoice Email Not Sent' }"
                        v-else
                        class="text-gray-500"
                      >
                        <i class="fas fa-paper-plane"></i>
                      </span>
                    </div>

                    <div class="ml-4">
                      <span
                        v-tippy="{ content: 'Invoice Seen' }"
                        v-if="invoice.seen_at"
                        class="text-il-teal"
                      >
                        <i class="fas fa-envelope-open-text"></i>
                      </span>
                      <span
                        v-tippy="{ content: 'Invoice Not Seen' }"
                        v-else
                        class="text-gray-500"
                      >
                        <i class="fas fa-envelope"></i>
                      </span>
                    </div>

                    <div class="ml-4">
                      <span
                        v-tippy="{ content: 'Invoice Paid' }"
                        v-if="invoice.paid_at"
                        class="text-il-teal"
                      >
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                      <span
                        v-tippy="{ content: 'Invoice Not Paid' }"
                        v-else
                        class="text-gray-500"
                      >
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>

                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div
                      class="flex"
                    >
                      <div
                        v-if="downloadLoading"
                        class="mr-2 py-1 px-2 uppercase rounded-lg text-sm border border-il-dark text-white bg-il-dark"
                      >
                        <i class="fas fa-cloud-download-alt"></i> Processing...
                      </div>
                      <div
                        v-if="!downloadLoading"
                        @click="download(client,invoice)"
                        class="mr-2 py-1 px-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark text-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out"
                      >
                        <i class="fas fa-cloud-download-alt"></i> Download
                      </div>
                      <a
                        v-if="!downloadLoading"
                        :href="$store.state.siteUrl + 'invoice/client-preview/' + invoice.company_uuid + '/' + invoice.client_uuid +'/' + invoice.uuid"
                        target="_blank"
                        class="mr-2 py-1 px-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark text-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out"
                      >
                        <i class="fas fa-eye"></i> Preview
                      </a>
                      <div
                        @click="navigateToMX('clients/new-invoice', { client_uuid: invoice.client_uuid, invoice_uuid: invoice.uuid })"
                        class="mr-2 py-1 px-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark text-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out"
                        v-if="!invoice.paid_at && !invoice.seen_at && !invoice.sent_at && !downloadLoading"
                      >
                        <i class="fas fa-edit"></i> Edit
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import clientInvoiceRequests from '../../helpers/clientInvoiceRequests';
  import { mapGetters, mapMutations } from 'vuex';
  import { directive } from 'vue-tippy';
  import lodash from 'lodash';
  import { format } from 'date-fns';
  import EmailInvoiceModal from '../../components/ClientInvoice/EmailInvoiceModal';

  export default {
    mounted() {

    },
    data() {
      return {
        clientUUID: this.$route.params.client_uuid,
        emailInvoiceModalVisible: false,
        selectedClient: null,
        selectedInvoice: null,
        markSeenLoading: false,
        markPaidLoading: false,
        downloadLoading: false
      }
    },
    components: {
      EmailInvoiceModal
    },
    directives: {
      'v-tippy': directive,
    },
    methods: {
      ...mapMutations([
        'setClientInvoiceSeenStatusX',
        'setClientInvoicePaidStatusX'
      ]),
      download(client,invoice) {
        if(!this.downloadLoading) {
          var that = this;
          this.downloadLoading = true;
          clientInvoiceRequests.downloadPDF(
            invoice.uuid,
            that.getUserCurrentCompanyX.uuid,
            client.uuid
          )
          .then((downloadResponse) => {
            const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'invoice.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            that.downloadLoading = false;
            // window.scrollTo(0,document.body.scrollHeight);
          }).catch(error => {
            that.downloadLoading = false;
            // console.log(error);
          });
        }
      },
      showEmailInvoiceModal(client,invoice) {
        this.selectedInvoice = invoice;
        this.selectedClient = client;
        this.emailInvoiceModalVisible = true;
      },
      markAsSeen(invoiceId) {
        if(!this.markSeenLoading) {
          this.markSeenLoading = true;
          clientInvoiceRequests.markSeen(invoiceId)
          .then((response) => {
            this.setClientInvoiceSeenStatusX(response.data.invoice);
            this.popSuccessMessageMX(response.data.message);
            this.markSeenLoading = false;
          }).catch(error => {
            console.log(error);
            this.popErrorsMX(error.response.data.errors);
            this.markSeenLoading = false;
          });
        }
      },
      markAsPaid(invoiceId) {
        if(!this.markPaidLoading) {
          this.markPaidLoading = true;
          clientInvoiceRequests.markPaid(invoiceId)
          .then((response) => {
            this.setClientInvoicePaidStatusX(response.data.invoice);
            this.popSuccessMessageMX(response.data.message);
            this.markPaidLoading = false;
          }).catch(error => {
            console.log(error);
            this.popErrorsMX(error.response.data.errors);
            this.markPaidLoading = false;
          });
        }
      },
      formatDate(someDate) {
        // var timestamp = new Date();
        return format(Date.parse(someDate), 'do MMM, yyyy');
      }
    },
    computed: {
      ...mapGetters([
        'getUserCurrentCompanyX'
      ]),
      client() {
        var that = this;
        return lodash.find(this.getUserCurrentCompanyX.clients, function(c) {
          return c.uuid == that.clientUUID;
        });
      },
      orderedInvoices() {
        const invoices = this.client.client_invoices;
        return lodash.orderBy(invoices, ['created_at'], ['desc']);
      }
    },
  }
</script>